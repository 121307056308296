/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 14:04:50
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-07-30 17:12:12
 * @FilePath: \digital-ocms-kacontent-mobile\src\hooks\setting\index.ts
 * @Description:
 */
import { getAppEnvConfig } from '@/utils/env'
import type { GlobConfig } from '#/config'

export function useGlobSetting(): Readonly<GlobConfig> {
    const {
        VITE_GLOB_APP_TITLE,
        VITE_GLOB_APP_TITLE_CN,
        VITE_GLOB_API_URL,
        VITE_GLOB_APP_SHORT_NAME,
        VITE_GLOB_UPLOAD_URL,
        VITE_GLOB_IMG_URL,
        VITE_ENABLE_ERUDA,
    } = getAppEnvConfig()

    // Take global configuration
    const glob: Readonly<GlobConfig> = {
        title: VITE_GLOB_APP_TITLE,
        titleCN: VITE_GLOB_APP_TITLE_CN,
        apiUrl: VITE_GLOB_API_URL,
        shortName: VITE_GLOB_APP_SHORT_NAME,
        uploadUrl: VITE_GLOB_UPLOAD_URL,
        imgUrl: VITE_GLOB_IMG_URL,
        enableEruda: VITE_ENABLE_ERUDA,
    }
    return glob as Readonly<GlobConfig>
}
